import '../styles/Footer.css'
import {Link} from "react-router-dom";

const Footer = () => {

    return(
        <div className='footer-container'>
            <span> Copyright © Curious Minds Hub 2023 |
                <Link to='/privacy-policy' className='link'> Privacy Policy </Link>  |
                <Link to='/terms-conditions' className='link'> Terms & Conditions </Link>  |
                <Link to='/contact' className='link'> Contact </Link>
                <br/> Continue Reading The News That You're Interested In!


            </span>

        </div>

    )

}

export default Footer